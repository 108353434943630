import "core-js/modules/es.array.push.js";
import countrySelectComp from "@/components/countrySelectComp.vue";
import captchasInfoDIalog from "@/components/captchasInfoDIalog.vue";
import { createTeamPrompt } from "@/util/createTeamPrompt";
import { mapGetters } from "vuex";
export default {
  components: {
    countrySelectComp,
    captchasInfoDIalog
  },
  data() {
    return {
      isPhoneLogin: true,
      isLoading: false,
      showPrefixPopup: false,
      verification_key: "",
      phoneRegistFormData: {
        mobile_prefix: "+86",
        mobile_number: "",
        mobile_prefix_code: "CN",
        verification_code: "",
        account: "",
        password: "",
        invite_id: ""
      },
      defaultCountrySelectedData: {
        areaName: "中国大陆",
        checkKey: "^(86){0,1}1\\d{10}$",
        code: "86",
        domainAbbreviation: "CN"
      },
      countdown: 0,
      COUNTDOWN_START: 60,
      emailRegistFormData: {
        verification_code: "",
        email: "",
        account: "",
        password: "",
        invite_id: ""
      },
      isWeChat: this.$utils.isWX() // 这里假设$utils已经通过Vue插件机制添加到Vue原型上
    };
  },
  created() {
    var _this$$route$query;
    this.phoneRegistFormData.invite_id = (_this$$route$query = this.$route.query) === null || _this$$route$query === void 0 ? void 0 : _this$$route$query.invite_id;
  },
  methods: {
    onCaptchaConfirm(key) {
      this.verification_key = key;
    },
    onSendCode(isPhone) {
      if (isPhone) {
        if (!this.phoneRegistFormData.mobile_number) {
          this.$toast({
            type: "fail",
            message: "请输入手机号",
            forbidClick: true
          });
          return false;
        }
        this.$refs.captchasInfoDIalog.showDialog(this.phoneRegistFormData.mobile_number, true);
      }
      if (!isPhone) {
        if (!this.emailRegistFormData.email) {
          this.$toast({
            type: "fail",
            message: "请输入邮箱地址",
            forbidClick: true
          });
          return false;
        }
        this.$refs.captchasInfoDIalog.showDialog(this.emailRegistFormData.email, false);
      }
      this.getCaptches();
    },
    getCaptches() {
      // 开始倒计时
      this.countdown = this.COUNTDOWN_START;
      const intervalId = setInterval(() => {
        this.countdown--;
        if (this.countdown <= 0) {
          clearInterval(intervalId); // 倒计时结束，清除定时器
        }
      }, 1000); // 每秒更新一次倒计时
    },
    onConfirmPrefix(value) {
      this.phoneRegistFormData.mobile_prefix = `+${value.code}`;
      this.phoneRegistFormData.mobile_prefix_code = value.domainAbbreviation;
      this.showPrefixPopup = false;
      this.defaultCountrySelectedData = value;
    },
    onPhoneSubmit() {
      let postData = {
        ...this.phoneRegistFormData
      };
      postData.mobile_prefix = postData.mobile_prefix.slice(1);
      postData.verification_key = this.verification_key;
      this.registHandle(postData, true);
    },
    onEmailSubmit() {
      let postData = {
        ...this.emailRegistFormData
      };
      postData.verification_key = this.verification_key;
      this.registHandle(postData, false);
    },
    registHandle(postData, bol) {
      this.isLoading = true;
      this.$axios.phoneRegister(postData, bol).then(res => {
        if (res.code === 1) {
          this.$notify({
            message: "您的账号创建成功，请前往登录",
            type: "success",
            onClose: () => {
              this.isLoading = false;
              this.returnLogin();
            }
          });
        } else {
          this.isLoading = false;
        }
      }).catch(() => {
        this.isLoading = false;
      });
    },
    returnLogin() {
      this.$router.push("/login");
    },
    onPhoneFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    onEmailFailed(errorInfo) {
      console.log("failed", errorInfo);
    },
    validatorPhone(val) {
      let reg = new RegExp(this.defaultCountrySelectedData.checkKey);
      let isValid = reg.test(this.defaultCountrySelectedData.code + "" + val);
      if (isValid) {
        return true;
      } else {
        return "请输入正确的手机号";
      }
    },
    noTeamHandler() {
      createTeamPrompt({
        models: this.getGptModels,
        onConfirm: data => {
          console.log("Form submitted with:", data);
          this.$axios.createTeamHandle(data).then(res => {
            if (res.code === 1) {
              this.$store.commit("CHANGE_TEAM_PARAM", {
                curr_team_id: res.data.team_id,
                curr_team_name: data.team_name,
                gpt_model_id: data.gpt_model_id,
                team_storage_id: res.data.team_storage_id
              });
              this.$store.dispatch("getUserTeamList");
              this.$store.dispatch("getGptConfigDatas");
            }
          }).catch(() => {});
        },
        onCancel: () => {
          console.log("Dialog canceled");
        }
      });
    }
  },
  computed: {
    ...mapGetters(["getGptModels"])
  }
};